<template>
  <div class="planet">
    <div class="user">
      <img v-bind:src="user.avatar_url" class="avatar" />
      <div class="nickname">{{user.nickname}}</div>
      <div class="desc">获得了一张粗门[星球舱]系列数字飞盘藏品</div>
    </div>
    <div class="frisbee">
      <div class="cover-wrap">
        <img v-bind:src="collection.url" class="cover" />
      </div>
      <div class="name">{{collection.name}}</div>
      <div class="details">
        <div class="item">
          <div class="title">
            <div class="dot"></div>
            藏品ID
          </div>
          <div class="value ellipsis">{{collection.collection_id}}</div>
        </div>
        <div class="item">
          <div class="title">
            <div class="dot"></div>
            拥有者
            </div>
          <div class="value">{{user.nickname}}</div>
        </div>
        <div class="item desc">
          <div class="title">
            <div class="dot"></div>
            藏品简介
          </div>
          <div class="value">{{collection.description}}</div>
        </div>
      </div>
    </div>
    <div class="qrcode-wrap">
      <img src="../assets/images/digital-frisbee.png" class="qrcode" />
    </div>
  </div>
</template>
<script>
import $fetch from '@/utils/fetch.js';
import dayjs from 'dayjs';
export default {
  name: 'frisbee_planet',
  data() {
    return {
      user: {
        avatar: '',
        nickname: '',
      },
      collection: {
        url: '',
        name: ''
      }
    }
  },
  created() {
    const { cid } = this.$route.query;
    $fetch.post("GetUserCollection", {userCollectionId: cid}).then(res => {
      const {collection, user} = res;
      this.user = user;
      this.collection = collection;

      console.log(collection, user);
    });
    
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/global.scss";
  div {
      box-sizing: border-box;
    }
  .planet {
    width: px2rem(750);
    height: px2rem(1872);
    background: url('../assets/images/planet_bg.png') no-repeat;
    background-size: cover;
    padding-top: px2rem(88);
    position: relative;
    > .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar {
        width: px2rem(96);
        height: px2rem(96);
        border-radius: 50%;
      }
      .nickname {
        font-size: px2rem(40);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        font-weight: normal;
        color: #FFFFFF;
        line-height: px2rem(48);
        margin-top: px2rem(16);
      }
      .desc {
        font-size: px2rem(28);
        font-weight: normal;
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        color: #FFFFFF;
        line-height: px2rem(48);
      }
    }
    > .frisbee {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: px2rem(24);
      .cover-wrap {
        width: px2rem(512);
        height: px2rem(496);
        display: flex;
        justify-content: center;
        padding-top: px2rem(88);
        .cover {
          width: px2rem(285);
          height: px2rem(285);
        }
      }
      .name {
        font-size: px2rem(40);
        font-weight: normal;
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        color: #26FF95;
        line-height: px2rem(64);
        margin-top: px2rem(40);
      }
      .details {
        margin-top: px2rem(110);
        width: 100%;
        .item {
          height: px2rem(96);
          margin-left: px2rem(72);
          margin-right: px2rem(64);
          font-size: px2rem(28);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
          font-weight: normal;
          color: #26FF95;

          line-height: px2rem(40);
          display: flex;
          flex-direction: row;
          align-items: center;

          box-shadow: inset 0px -1px 0px 0px;

          &:last-child {
            box-shadow: none;
          }

          &.desc {
            flex-direction: column;
            align-items: flex-start;
            margin-top: px2rem(30);

            > div.value {
              padding: px2rem(40) 0;
              text-align: left;
            }
          }

          > div.title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .dot {
              width: px2rem(8);
              height: px2rem(8);
              background: #fff;
              border-radius: 50%;
              margin-right: px2rem(10);
            }
          }
          
          > div.value {
            flex: 1;
            font-size: px2rem(28);
            font-weight: normal;
            color: #FFFFFF;
            line-height: px2rem(40);
            font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
            text-align: right;
            &.ellipsis {
              margin-left: px2rem(60);
              overflow: hidden;
              text-overflow: ellipsis; //文本溢出显示省略号
              white-space: nowrap; //文本不会换行

            }
          }
        }
      }
    }
    .qrcode-wrap {
      position: absolute;
      bottom: px2rem(150);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .qrcode {
        width: px2rem(160);
        height: px2rem(160);
      }
    }
  }
</style>
